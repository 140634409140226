import React, { useEffect, useState } from 'react'
import Amplify from '@aws-amplify/core'
import DataGrid, { Column } from 'react-data-grid'
import AmplifyConfig from '../apis/amplify'
import '../styles/index.css'
import { scanLogs } from '../apis/logs'
import { FiRefreshCcw } from 'react-icons/fi'

Amplify.configure({
  API: {
    endpoints: AmplifyConfig.aws_cloud_logic_custom,
  },
})

type LogEntry = {
  service: string
  ts:      number
  level:   'info'|'error'|'panic'
  data:    any
}

const COLUMNS = [
  { key: 'service', name: 'ID',    width:50  },
  { key: 'time',    name: 'Time',  width:200 },
  { key: 'level',   name: 'Level', width:50 },
  { key: 'data',    name: 'Data', },
]

const SAMPLE_ROWS = []

// markup
const IndexPage = () => {

  const [rows, setRows] = useState(SAMPLE_ROWS)
  const [updated, setUpdated] = useState<string>('')

  useEffect(() => {
    updateLogs()
  }, [])

  const updateLogs = () => {
    scanLogs()
    .then(res => {
      updateTable(res.logs)
      calcUpdatedAgo()
    })
  }

  const updateTable = (logs:LogEntry[]) => {
    setRows(logs.map(ll => ({
      service: ll.service,
      time:    new Date(ll.ts).toLocaleString(),
      level:   ll.level,
      data:    JSON.stringify(ll.data),
    })))
  }

  const calcUpdatedAgo = () => {
    setUpdated(new Date().toLocaleString())
    // if (!updated) return ''
    // const min = Math.floor((new Date().getTime() - updated) / (1000*60))
    // setMinAgo(min + 'min ago')
  }

  const onClick = (row:any, col:any) => {
    console.log(row.data)
  }

  return (
    <main>
      <title>Home</title>
      <div className='flex flex-col h-screen'>
        <div className='flex flex-row justify-between p-4 h-16'>
          <button onClick={() => updateLogs()} >
            <FiRefreshCcw />
          </button>
          <div>
            last updated: {updated}
          </div>
        </div>
        <DataGrid className='h-full w-full flex-1'
          columns={COLUMNS} rows={rows}
          onRowClick={onClick}
        />
      </div>
    </main>
  )
}

export default IndexPage
