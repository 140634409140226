import { API } from 'aws-amplify'
import AmplifyConfig from './amplify'

const apiName = AmplifyConfig.aws_cloud_logic_custom[0].name

export const scanLogs = () => {
  return API.get(
    apiName,
    '/logs',
    {},
  )
}
